const mapSymToIcon = {
  Airport: "airport",
  Bank: "bank",
  Bar: "beer",
  Campground: "campsite",
  Cemetery: "cemetery",
  Church: "religious-christian",
  Civil: "town-hall",
  "Controlled Area": "police",
  "Convenience Store": "grocery",
  "Department Store": "shop",
  "Fast Food": "fast-food",
  Forest: "park",
  "Gas Station": "fuel",
  "Golf Course": "golf",
  "Live Theater": "theatre",
  Lodging: "lodging",
  "Medical Facility": "hospital",
  "Movie Theater": "cinema",
  Museum: "museum",
  Park: "park",
  "Parking Area": "parking",
  Pharmacy: "pharmacy",
  "Police Station": "police",
  "Post Office": "post",
  Restaurant: "restaurant",
  "Restricted Area": "police",
  School: "school",
  "Shopping Center": "shop",
  "Ski Resort": "skiing",
  "Skiing Area": "skiing",
  Stadium: "soccer",
  "Swimming Area": "swimming",
  Zoo: "zoo",

  Building: "town-hall",
  Flag: "flag",
  Information: "information",
  Lodge: "lodging",
  "Scenic Area": "viewpoint",
  "Skull and Crossbones": "marker",
  Waypoint: "marker",
};

export default mapSymToIcon;
