import React, { useState } from "react";

import { Alert, Box, Button, Container, Stack } from "@mui/material";

import PublicIcon from "@mui/icons-material/Landscape";

import Footer from "./Footer";
import Header from "./Header";
import Map from "./Map";

import { gpx } from "@tmcw/togeojson";
import geojsonCoords from "@mapbox/geojson-coords";

const validateGeoJson = (geoJson) => {
  const coordinates = geojsonCoords(geoJson);

  return coordinates.length > 0;
};

const App = () => {
  const [geoJson, setGeoJson] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const content = await file.text();

    const gpxDom = new DOMParser().parseFromString(content, "application/xml");
    const geoJsonData = gpx(gpxDom);

    console.log(geoJsonData);

    if (validateGeoJson(geoJsonData)) {
      setError("");
      setGeoJson(geoJsonData);
    } else {
      setError("No data found in GPX file");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Header />

      {error && (
        <Box sx={{ my: 2, mx: "auto" }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {geoJson ? (
        <>
          <Box sx={{ my: 2, mx: "auto" }}>
            <Button variant="contained" onClick={() => setGeoJson(null)}>
              Clear
            </Button>
          </Box>
          <Box sx={{ my: 2 }} flex={1} overflow="auto">
            <Map geoJson={geoJson} />
          </Box>
        </>
      ) : (
        <Stack spacing={2} sx={{ my: 2, alignItems: "center" }}>
          <Button variant="contained" component="label">
            Upload
            <input
              hidden
              accept=".gpx"
              type="file"
              onChange={handleFileChange}
            />
          </Button>
          <Box>Upload a GPX file to view its contents</Box>
          <PublicIcon
            htmlColor="rgba(0, 0, 0, 0.1)"
            fontSize="large"
            sx={{
              width: "20vw",
              height: "20vh",
              minWidth: 128,
              minHeight: 128,
            }}
          />
        </Stack>
      )}

      <Footer />
    </Container>
  );
};

export default App;
