import { Layer, Popup, Source, useMap } from "react-map-gl";
import { useState } from "react";

const POINT_SOURCE_ID = "POINT_SOURCE_ID";
const POINT_LAYER_ID = "POINT_LAYER_ID";

const PointFeatures = ({ pointSource }) => {
  const { current: map } = useMap();

  const [coords, setCoords] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  map.on("mouseenter", POINT_LAYER_ID, (e) => {
    const {
      features: [
        {
          geometry: { coordinates },
          properties: { name: description },
        },
      ],
    } = e;

    setPopupContent(description);

    setCoords(coordinates);
    setShowPopup(true);
  });

  map.on("mouseleave", POINT_LAYER_ID, () => {
    setShowPopup(false);
  });

  map.on("mouseenter", [POINT_LAYER_ID], () => {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", [POINT_LAYER_ID], () => {
    map.getCanvas().style.cursor = "";
  });

  return (
    <Source
      id={POINT_SOURCE_ID}
      type="geojson"
      generateId={true}
      data={pointSource}
    >
      <Layer
        id={POINT_LAYER_ID}
        type="symbol"
        layout={{ "icon-image": ["coalesce", ["get", "sym"], "information"] }}
      />
      {showPopup && (
        <Popup
          longitude={coords[0]}
          latitude={coords[1]}
          closeButton={false}
          closeOnClick={false}
        >
          {popupContent}
        </Popup>
      )}
    </Source>
  );
};

export default PointFeatures;
