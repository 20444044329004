import React from "react";

import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import TimelineIcon from "@mui/icons-material/Timeline";
import RoomIcon from "@mui/icons-material/Room";

const NavBar = ({ open, geoJson, onPointClick, onLineClick }) => {
  const points = geoJson.features.filter(
    (feature) => feature.geometry.type === "Point"
  );

  const lines = geoJson.features.filter(
    (feature) => feature.geometry.type === "LineString"
  );

  const pointItems = points.map((point) => (
    <ListItemButton component="a" onClick={() => onPointClick(point)}>
      <ListItemIcon>
        <RoomIcon />
      </ListItemIcon>
      <ListItemText primary={point.properties.name} />
    </ListItemButton>
  ));

  const lineItems = lines.map((line) => (
    <ListItemButton component="a" onClick={() => onLineClick(line)}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary={line.properties.name} />
    </ListItemButton>
  ));

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="persistent"
      PaperProps={{
        sx: { width: "18vw" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "0 8px",
        }}
      >
        <List dense={true}>
          <ListSubheader>Tracks</ListSubheader>
          {lineItems}
          <ListSubheader>Points</ListSubheader>
          {pointItems}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavBar;
