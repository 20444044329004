import { featureCollection } from "@turf/helpers";
import length from "@turf/length";
import along from "@turf/along";
import { Layer, Source } from "react-map-gl";

const ROUTE_SOURCE_ID = "ROUTE_SOURCE_ID";
const LINE_LABEL_SOURCE_ID = "LINE_LABEL_SOURCE_ID";
const LINE_LAYER_ID = "LINE_LAYER_ID";
const LINE_LABEL_LAYER_ID = "LINE_LABEL_LAYER_ID";

const colours = [
  "#FFBE36",
  "#FF6447",
  "#77FF47",
  "#D547FF",
  "#FF9E47",
  "#47DBFF",
  "#FF4770",
];

const LineFeatures = ({ lineSource }) => {
  lineSource.features
    .filter((feature) => !feature.properties.color)
    .forEach((feature, i) => {
      if (feature.properties.gpxx_TrackExtension) {
        feature.properties.color =
          feature.properties.gpxx_TrackExtension.trim();
      } else {
        feature.properties.color = colours[i % colours.length];
      }
    });

  const lineMidpoints = featureCollection(
    lineSource.features.map((feature) => {
      const lineLength = length(feature);
      const lineAlong = along(feature, lineLength / 2);

      return { ...lineAlong, properties: feature.properties };
    })
  );

  return (
    <>
      <Source
        id={ROUTE_SOURCE_ID}
        type="geojson"
        generateId={true}
        data={lineSource}
      >
        <Layer
          id={LINE_LAYER_ID}
          type="line"
          layout={{ "line-join": "round", "line-cap": "round" }}
          paint={{
            "line-color": ["get", "color"],
            "line-width": 5,
            "line-opacity": 0.7,
          }}
          filter={["==", ["geometry-type"], "LineString"]}
        />
      </Source>
      <Source id={LINE_LABEL_SOURCE_ID} type="geojson" data={lineMidpoints}>
        <Layer
          id={LINE_LABEL_LAYER_ID}
          type="symbol"
          layout={{
            "symbol-placement": "point",
            "text-field": ["to-string", ["get", "name"]],
            "text-max-angle": 180,
            "text-radial-offset": 0.5,
            "text-rotation-alignment": "viewport",
            "text-size": 12,
            "text-variable-anchor": ["right", "left", "top", "bottom"],
          }}
          paint={{
            "text-color": "#c51b1b",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(255,255,255)",
            "text-halo-width": 2,
          }}
        />
      </Source>
    </>
  );
};

export default LineFeatures;
