import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  TextField,
} from "@mui/material";

const EditLine = ({ feature, onSave, onRemove }) => {
  const [name, setName] = useState(feature.properties.name ?? "");
  const [color, setColor] = useState(feature.properties.color ?? "");

  const handleRemove = () => {
    onRemove?.(feature);
  };

  const handleSave = () => {
    feature.properties.name = name ? name : null;
    feature.properties.color = color ? color : null;

    onSave?.(feature);
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Line" />
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <TextField
            label="Name"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <TextField
            label="Color"
            size="small"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </FormControl>
      </CardContent>
      <CardActions>
        {onRemove && <Button onClick={handleRemove}>Remove</Button>}
        {onSave && <Button onClick={handleSave}>Save</Button>}
      </CardActions>
    </Card>
  );
};

export default EditLine;
