import React from "react";

import { useControl } from "react-map-gl";

import EditPointCard from "../EditPointCard";
import EditLineCard from "../EditLineCard";
import { useDraw } from "../DrawProvider";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

function DrawControl({ geoJson, setSidebarOpen, setSidebarContent }) {
  const [draw] = useDraw();

  const onSelectionChange = (e) => {
    if (e.features.length > 0) {
      const feature = e.features[0];

      if (feature.geometry.type === "Point") {
        const onSave = (saved) => {
          setSidebarOpen(false);
          draw.setFeatureProperty(feature.id, "name", saved.properties.name);
          draw.setFeatureProperty(feature.id, "sym", saved.properties.sym);
          draw.changeMode("simple_select");
        };

        setSidebarContent(
          <EditPointCard key={feature.id} onSave={onSave} feature={feature} />
        );
        setSidebarOpen(true);
      } else if (feature.geometry.type === "LineString") {
        const onSave = (saved) => {
          setSidebarOpen(false);
          draw.setFeatureProperty(feature.id, "name", saved.properties.name);
          draw.setFeatureProperty(feature.id, "color", saved.properties.color);
          draw.changeMode("simple_select");
        };

        setSidebarContent(
          <EditLineCard key={feature.id} onSave={onSave} feature={feature} />
        );
        setSidebarOpen(true);
      }
    } else {
      // No selection
      setSidebarOpen(false);
    }
  };

  useControl(
    () => draw,
    ({ map }) => {
      draw.set(geoJson);
      map.on("draw.selectionchange", onSelectionChange);
    },
    ({ map }) => {
      map.on("draw.selectionchange", onSelectionChange);
    },
    {
      position: "top-left",
    }
  );

  return null;
}

export default DrawControl;
