import { createRoot } from "react-dom/client";

const createReactElement = (children) => {
  const container = document.createElement("div");
  const root = createRoot(container);
  root.render(children);
  return container;
};

export default createReactElement;
