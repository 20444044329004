import { Box, Typography } from "@mui/material";
import React from "react";

const Header = () => {
  return (
    <Box sx={{ my: 4, mx: "auto" }}>
      <Typography variant="h4" gutterBottom>
        <img
          src="logo.png"
          alt="GPX Vision"
          style={{
            width: 64,
            height: 64,
            verticalAlign: "middle",
            marginRight: 10,
          }}
        />
        GPX Vision
      </Typography>
    </Box>
  );
};

export default Header;
