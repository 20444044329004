import React, { useRef, useState } from "react";

import geojsonCoords from "@mapbox/geojson-coords";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import Sidebar from "./Sidebar";
import mapSymToIcon from "./mapSymToIcon";
import EditControl from "./controls/EditControl";
import NavBar from "./Navbar";

import bbox from "@turf/bbox";

import MapGl, {
  FullscreenControl,
  GeolocateControl,
  MapProvider,
  NavigationControl,
} from "react-map-gl";
import LineFeatures from "./LineFeatures";
import DrawControl from "./controls/DrawControl";
import { DrawProvider } from "./DrawProvider";
import SubmitCancelControl from "./controls/SubmitCancelControl";
import PointFeatures from "./PointFeatures";

mapboxgl.accessToken =
  "pk.eyJ1IjoicGpoYWxsIiwiYSI6ImNsZG03dWdqazA2czQzd21kenc4bWI3eWUifQ.229uUcIDfeuQeYZliTt47A";

const getBounds = (geoJson) => {
  // Geographic coordinates of the LineString
  const coordinates = geojsonCoords(geoJson);

  // Create a 'LngLatBounds' with both corners at the first coordinate.
  const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord);
  }

  return bounds;
};

const Map = ({ geoJson: initialGeoJson = {} }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState(null);

  const [geoJson, setGeoJson] = useState(initialGeoJson);
  const [editing, setEditing] = useState(false);

  const mapRef = useRef();

  const lineFeatures = geoJson.features.filter(
    (feature) => feature.geometry.type === "LineString"
  );

  const lineSource = { ...geoJson, features: lineFeatures };

  const pointFeatures = geoJson.features
    .filter((feature) => feature.geometry.type === "Point")
    .map((feature) => {
      if (!mapSymToIcon[feature.properties.sym]) {
        console.log(`Not found: ${feature.properties.sym}`);
      }

      const sym = mapSymToIcon[feature.properties.sym];

      return { ...feature, properties: { ...feature.properties, sym } };
    });

  const pointSource = { ...geoJson, features: pointFeatures };

  return (
    <MapProvider>
      <NavBar
        open={true}
        geoJson={geoJson}
        onPointClick={(point) =>
          mapRef.current.easeTo({ center: point.geometry.coordinates })
        }
        onLineClick={(line) => {
          const bounds = bbox(line);
          mapRef.current.fitBounds(bounds, { padding: 24 });
        }}
      />
      <MapGl
        mapboxAccessToken="pk.eyJ1IjoicGpoYWxsIiwiYSI6ImNsZG03dWdqazA2czQzd21kenc4bWI3eWUifQ.229uUcIDfeuQeYZliTt47A"
        initialViewState={{
          bounds: getBounds(geoJson).toArray(),
          fitBoundsOptions: { padding: 24 },
        }}
        style={{ height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        ref={mapRef}
      >
        <GeolocateControl />
        <FullscreenControl />
        <NavigationControl showCompass={false} />

        {editing ? (
          <DrawProvider>
            <DrawControl
              geoJson={geoJson}
              setSidebarOpen={setSidebarOpen}
              setSidebarContent={setSidebarContent}
            />
            <SubmitCancelControl
              setEditing={setEditing}
              setGeoJson={setGeoJson}
            />
          </DrawProvider>
        ) : (
          <>
            <EditControl setEditing={setEditing} position="top-left" />
            <LineFeatures lineSource={lineSource} />
            <PointFeatures pointSource={pointSource} />
          </>
        )}
      </MapGl>
      <Sidebar
        sidebarOpen={sidebarOpen}
        sidebarContent={sidebarContent}
        onCloseClick={() => setSidebarOpen(false)}
      />
    </MapProvider>
  );
};

export default Map;
