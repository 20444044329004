import { Box } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box
      sx={{
        my: 4,
        mx: "auto",
        marginTop: "auto",
      }}
    >
      &copy; 2023
    </Box>
  );
};

export default Footer;
