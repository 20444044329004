import React, { useContext } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import drawStyles from "./drawStyles";

export const DrawContext = React.createContext();

export const DrawProvider = ({ children }) => {
  const [draw, setDraw] = React.useState(
    new MapboxDraw({
      controls: {
        polygon: false,
        combine_features: false,
        uncombine_features: false,
      },
      boxSelect: false,
      userProperties: true,
      styles: [...drawStyles],
    })
  );

  return (
    <DrawContext.Provider value={{ draw, setDraw }}>
      {children}
    </DrawContext.Provider>
  );
};

export const useDraw = () => {
  const { draw, setDraw } = useContext(DrawContext);
  return [draw, setDraw];
};
