import React from "react";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const SideBar = ({ sidebarOpen, sidebarContent, onCloseClick }) => {
  return (
    <Drawer
      anchor="right"
      open={sidebarOpen}
      variant="persistent"
      PaperProps={{
        sx: { width: "18vw" },
      }}
    >
      <Box>
        <IconButton onClick={onCloseClick}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "0 8px",
        }}
      >
        <Typography variant="h5">{sidebarContent?.properties?.name}</Typography>
        {sidebarContent}
      </Box>
    </Drawer>
  );
};

export default SideBar;
