import React from "react";

import createReactElement from "../createReactElement";
import EditIcon from "@mui/icons-material/Edit";
import { useControl } from "react-map-gl";

class EditControlClass {
  constructor({ setEditing }) {
    this.setEditing = setEditing;
  }

  onAdd(map) {
    this._map = map;

    const onEdit = () => {
      this.setEditing(true);
    };

    this._container = createReactElement(
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button title="Edit" onClick={onEdit}>
          <EditIcon />
        </button>
      </div>
    );

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

function EditControl({ setEditing }) {
  useControl(
    () => {
      return new EditControlClass({ setEditing });
    },
    {
      position: "top-left",
    }
  );

  return null;
}

export default EditControl;
