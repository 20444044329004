import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import mapSymToIcon from "./mapSymToIcon";

const EditPoint = ({ feature, onRemove, onSave }) => {
  const [name, setName] = useState(feature.properties.name ?? "");
  const [symbol, setSymbol] = useState(feature.properties.sym ?? "");

  const handleRemove = () => {
    onRemove?.(feature);
  };

  const handleSave = () => {
    feature.properties.name = name ? name : null;
    feature.properties.sym = symbol ? symbol : null;

    onSave?.(feature);
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Point" />
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <TextField
            label="Name"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Category</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={symbol}
            label="Category"
            onChange={(e) => setSymbol(e.target.value)}
          >
            {Object.values(mapSymToIcon).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
      <CardActions>
        {onRemove && <Button onClick={handleRemove}>Remove</Button>}
        {onSave && <Button onClick={handleSave}>Save</Button>}
      </CardActions>
    </Card>
  );
};

export default EditPoint;
