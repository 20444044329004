import React from "react";

import createReactElement from "../createReactElement";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useDraw } from "../DrawProvider";
import { useControl } from "react-map-gl";

class SubmitCancelControlClass {
  constructor({ draw, setGeoJson, setEditing }) {
    this.draw = draw;
    this.setGeoJson = setGeoJson;
    this.setEditing = setEditing;
  }

  onAdd(map) {
    this._map = map;

    const onSave = () => {
      this.setGeoJson(this.draw.getAll());
      this.setEditing(false);
    };

    const onCancel = () => {
      this.setEditing(false);
    };

    this._container = createReactElement(
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button title="Save" onClick={onSave}>
          <CheckIcon />
        </button>
        <button title="Cancel" onClick={onCancel}>
          <ClearIcon />
        </button>
      </div>
    );

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

const SubmitCancelControl = ({ setEditing, setGeoJson }) => {
  const [draw] = useDraw();

  useControl(
    () => {
      return new SubmitCancelControlClass({ draw, setEditing, setGeoJson });
    },
    {
      position: "top-left",
    }
  );

  return null;
};

export default SubmitCancelControl;
